import {
  collection,
  doc,
  getDoc,
  getFirestore,
  runTransaction,
  query,
  where,
  onSnapshot,
  addDoc,
  deleteDoc,
  arrayUnion,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import moment from "moment";
import { setCases } from "../CasesSlice";
import axios from "axios";
import Constants from "../../constants/Constants";
const EMAIL_SERVICE_END_POINT =
  "https://4elyea23mfngeb3czfojlfucgm0hfyum.lambda-url.eu-west-1.on.aws";
const headers = {
  // Authorization: `Bearer ${Constants.whatsapp.WHATSAPP_TOKEN}`,
  "Content-Type": "application/json",
};

export const createCase = async (newCaseData) => {
  const firestore = getFirestore();
  const counterDocRef = doc(firestore, "metadata", "caseCounter");
  const casesCollectionRef = collection(firestore, "cases");

  const transaction = runTransaction(firestore, async (transaction) => {
    const counterDoc = await getDoc(counterDocRef);
    let currentCount = 0;

    if (counterDoc.exists()) {
      currentCount = counterDoc.data().count;
    }

    const newCount = currentCount + 1;

    transaction.set(counterDocRef, { count: newCount });

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = moment(currentDate).format("MM");
    const formattedCount = newCount.toString().padStart(2, "0");
    const caseNumber = `#mYSd-${year}-${month}-${formattedCount}`;

    const newCaseDocRef = doc(casesCollectionRef);

    transaction.set(newCaseDocRef, {
      caseNumber,
      ...newCaseData,
    });

    return caseNumber;
  });

  await transaction;
};

export const subscribeToCases = (dispatch, user) => {
  const casesCollectionRef = collection(db, "cases");
  const q = query(
    casesCollectionRef,
    // where("Status", "==", Constants.casesStages.CLOSED)
    where("caseRequester", "==", user.email)
  );

  const unsubscribe = onSnapshot(q, (snapshot) => {
    const cases = snapshot.docs.map((doc) => {
      const caseData = doc.data();
      // caseData.DateCreated = caseData.DateCreated.toDate();
      // caseData.DateUpdated = caseData.DateUpdated.toDate();

      return { id: doc.id, ...caseData };
    });

    dispatch(setCases(cases));
  });

  return unsubscribe;
};

export const updateCaseWithComment = async (caseId, payload) => {
  try {
    const db = getFirestore();
    const caseRef = doc(db, "cases", caseId);

    updateDoc(caseRef, {
      dateUpdated: new Date().toString(),
      comments: arrayUnion(payload),
    });
  } catch (error) {
    console.error("Error updating array:", error);
  }
};

export const updateCaseMasterData = async (caseId, payload) => {
  try {
    const db = getFirestore();
    const caseRef = doc(db, "cases", caseId);

    updateDoc(caseRef, payload);
  } catch (error) {
    console.error("Error updating array:", error);
  }
};

export const sendCaseUpdateEmail = (
  receiverEmailAddresses,
  caseNumber,
  messageBody
) => {
  const payload = {
    recipientEmailAddresses: receiverEmailAddresses,
    templateData: {
      caseNumber: caseNumber,
      message: messageBody,
      timestamp: moment().format("YYYY-MM-DD/HH:mm:ss"),
    },
    sourceEmail: "noreply@servicedesk.myplusplus.com",
    templateName: "case_update_template_v7",
  };

  return new Promise((resolve, reject) => {
    axios
      .post(
        EMAIL_SERVICE_END_POINT,
        payload
        //   ,
        //   {
        //   headers: headers,
        // }
      )
      .then((res) => {
        resolve("ok");
      })
      .catch((error) => {
        console.log("Error occurred: ", error);
        reject(error);
      });
  });
};
