import React, { useState, useEffect } from "react";
import {
  getAllSurveyQuestions,
  saveSurveyAnswers,
  getSurveyById,
} from "../../slices/api/surveyApi";
import { Link, useLocation } from "react-router-dom";

const Survey = () => {
  const [surveyQuestions, setSurveyQuestions] = useState([]);
  const [surveyAnswers, setSurveyAnswers] = useState({});
  const [surveyAnswered, setSurveyAnswered] = useState(false);
  const [surveyNotFoundError, setSurveyNotFoundError] = useState(false);
  const [idNotDefinedError, setIdNotDefinedError] = useState(false);
  const [surveySubmitted, setSurveySubmitted] = useState(false);
  const [submittingSurvey, setSubmittingSurvey] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  useEffect(() => {
    const fetchData = async () => {
      if (!id) {
        setIdNotDefinedError(true);
        return;
      }

      try {
        const survey = await getSurveyById(id);
        if (survey.answers) {
          setSurveyAnswered(true);
        } else {
          const questions = await getAllSurveyQuestions();
          setSurveyQuestions(questions);
        }
      } catch (error) {
        setSurveyNotFoundError(true);
      }
    };
    fetchData();
  }, [id]);

  const handleAnswerChange = (questionId, answer) => {
    setSurveyAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: answer,
    }));
  };

  const handleSubmit = async () => {
    setSubmittingSurvey(true);
    try {
      await saveSurveyAnswers(id, surveyAnswers);
      setSurveyAnswers({});
      setSurveySubmitted(true);
    } catch (error) {
      console.error("Error submitting survey answers: ", error);
      setSubmitError(true);
    }
    setSubmittingSurvey(false);
  };

  if (idNotDefinedError) {
    return <div>Error: ID is not defined in the URL.</div>;
  }

  if (surveyNotFoundError) {
    return <div>Error: Survey not found or the link is invalid.</div>;
  }

  if (surveyAnswered) {
    return (
      <div style={{ padding: "5%" }}>
        <h1 style={{ color: "#29bb89" }}>Survey</h1>
        <hr style={{ backgroundColor: "#29bb89" }} />
        <p>Our records show that this survey has been answered.</p>
        <p>You can now close this window.</p>
      </div>
    );
  }

  if (surveySubmitted) {
    return (
      <div style={{ padding: "5%" }}>
        <h1 style={{ color: "#29bb89" }}>Thank You!</h1>
        <p>
          Thank you for submitting the survey. Your feedback is greatly
          appreciated.
        </p>
        <p>You can now close this window.</p>
      </div>
    );
  }

  return (
    <div style={{ padding: "5%" }}>
      <h1 style={{ color: "#29bb89" }}>Survey</h1>
      <hr style={{ backgroundColor: "#29bb89" }} />
      {surveyQuestions.length > 0 ? (
        <>
          {surveyQuestions.map((question, index) => (
            <div key={question.id}>
              <h3 style={{ color: "#29bb89" }}>Question {index + 1}</h3>
              <p style={{ color: "#b3b3b3" }}>{question.question}</p>
              {question.question_type === "rating" ? (
                <div className="mb-3">
                  <div className="form-check">
                    <input
                      type="radio"
                      value="good"
                      className="form-check-input"
                      onChange={() => handleAnswerChange(question.id, "good")}
                    />
                    <label
                      className="form-check-label"
                      style={{ color: "#b3b3b3" }}
                    >
                      Good
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="radio"
                      value="maybe"
                      className="form-check-input"
                      onChange={() => handleAnswerChange(question.id, "maybe")}
                    />
                    <label
                      className="form-check-label"
                      style={{ color: "#b3b3b3" }}
                    >
                      Maybe
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="radio"
                      value="bad"
                      className="form-check-input"
                      onChange={() => handleAnswerChange(question.id, "bad")}
                    />
                    <label
                      className="form-check-label"
                      style={{ color: "#b3b3b3" }}
                    >
                      Bad
                    </label>
                  </div>
                </div>
              ) : (
                <div className="mb-3">
                  <label
                    htmlFor={`textarea-${index}`}
                    style={{ color: "#b3b3b3" }}
                  >
                    Answer:
                  </label>
                  <textarea
                    id={`textarea-${index}`}
                    name={`textarea-${index}`}
                    rows="4"
                    className="form-control"
                    style={{ color: "#b3b3b3", backgroundColor: "#f7f7f7" }}
                    onChange={(e) =>
                      handleAnswerChange(question.id, e.target.value)
                    }
                  ></textarea>
                </div>
              )}
              <hr style={{ backgroundColor: "#f7f7f7" }} />
            </div>
          ))}
          <button
            className="btn btn-primary mt-3"
            style={{ backgroundColor: "#29bb89" }}
            onClick={handleSubmit}
            disabled={submittingSurvey}
          >
            {submittingSurvey ? "Submitting..." : "Submit Survey"}
          </button>
          {submitError && (
            <p style={{ color: "red" }}>
              Error submitting the survey. Please try again.
            </p>
          )}
        </>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default Survey;
