import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { subscribeToChats } from "../../slices/api/chatsAPI";
import { setActiveChat, setChatStatusFilter } from "../../slices/ChatSlice";
import WhatsAppIcon from "../../assets/img/whatsapp.png";
import WebChatIcon from "../../assets/img/custom_chat.png";
import moment from "moment";
import Constants from "../../constants/Constants";

const ChatHistory = () => {
  const chat = useSelector((state) => state.chat);
  const authUser = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const handleChatClick = (conversation) => {
    dispatch(setActiveChat(conversation));
  };

  useEffect(() => {
    const unsubscribe =
      authUser && authUser.user && authUser.user.department
        ? subscribeToChats(dispatch, authUser.user.department)
        : false;

    return () => {
      unsubscribe();
    };
  }, [dispatch, authUser]);

  const handledFilterChatStatus = (status) => {
    dispatch(setChatStatusFilter(status));
  };

  return (
    <>
      <div className="chat-history">
        <div className="header-part">
          <h2>Live Chat</h2>
        </div>
        <div className="action-btn">
          <button
            type="button"
            className={`${
              chat.chatStatusFilter == Constants.chatStages.CHAT_OPENED
                ? "active-opt"
                : ""
            }`}
            onClick={() =>
              handledFilterChatStatus(Constants.chatStages.CHAT_OPENED)
            }
          >
            Unattended
          </button>
          <button
            type="button"
            className={`${
              chat.chatStatusFilter == Constants.chatStages.CLOSED
                ? "active-opt"
                : ""
            }`}
            onClick={() => handledFilterChatStatus(Constants.chatStages.CLOSED)}
          >
            Closed
          </button>
        </div>
        <div className="history-container">
          {chat.conversations.map((chatOnHistory) => {
            return chatOnHistory.Status == chat.chatStatusFilter ? (
              <div
                key={chatOnHistory.id}
                className={`history-item ${
                  chat.activeChat && chatOnHistory.id == chat.activeChat.id
                    ? "active-chat"
                    : ""
                }`}
                onClick={() => handleChatClick(chatOnHistory)}
              >
                <div className="">
                  {/* profile-img class to be revised */}
                  <img
                    src={
                      chatOnHistory.Channel == "WHATSAPP"
                        ? WhatsAppIcon
                        : WebChatIcon
                    }
                    alt="Profile broken"
                  />
                </div>
                <div className="message-and-name">
                  <h4 className="name">
                    {chatOnHistory.Channel == "WHATSAPP"
                      ? `+${chatOnHistory.id}`
                      : chatOnHistory.Name}
                  </h4>
                  <p className="last-message">
                    {
                      chatOnHistory.Messages[chatOnHistory.Messages.length - 1]
                        .MessageContent
                    }
                  </p>
                </div>
                <div className="time-and-badge">
                  <span className="time">
                    {moment(
                      chatOnHistory.Messages[chatOnHistory.Messages.length - 1]
                        .DateCreated
                    ).format("h:mm A")}
                  </span>
                  {chat.idForChatCurrentlyAttending &&
                  chat.idForChatCurrentlyAttending == chatOnHistory.id
                    ? "(attending)"
                    : ""}
                </div>
              </div>
            ) : (
              <></>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ChatHistory;
