import moment from "moment";

const ConversationToDownLoadAsPdf = ({ chatDataToDownload }) => {
  return (
    <>
      {chatDataToDownload
        ? chatDataToDownload.chatList.map((chat) => {
            return (
              <p key={chat.id}>
                <b>
                  {chat.sender == "client"
                    ? chatDataToDownload.clientNumber
                    : chatDataToDownload.agentName}
                </b>{" "}
                - {chat.MessageContent} -
                {moment
                  .utc(new Date(chat.DateCreated), "MM/DD/YYYY h:mm A")
                  .format("HH:mm DD MMM, YYYY")}
              </p>
            );
          })
        : ""}
    </>
  );
};

export default ConversationToDownLoadAsPdf;
