import React, { useState } from 'react';

const SingleCaseTabs = ({ activeTab, onTabChange }) => {
  return (
    <div>
      <div className="single-case-tab">
        <div
          className={`tab ${activeTab === 'conversation' ? 'active' : ''}`}
          onClick={() => onTabChange('conversation')}
        >
          Conversation
        </div>
        <div
          className={`tab ${activeTab === 'details' ? 'active' : ''}`}
          onClick={() => onTabChange('details')}
        >
          Details
        </div>
        <div
          className={`tab ${activeTab === 'resolution' ? 'active' : ''}`}
          onClick={() => onTabChange('resolution')}
        >
          Resolution
        </div>
        <div className={`tab ${activeTab === 'attachments' ? 'active' : ''}`} onClick={() => onTabChange('attachments')}>
          Attachments
        </div>
      </div>
    </div>
  );
}

export default SingleCaseTabs;
