import { getStorage, ref, uploadBytes } from "firebase/storage";
import { app } from "../firebase/firebase";

class StorageService {
  constructor() {
    this.storage = getStorage(app);
  }

  uploadFile = async (fileInput, folderName) => {
    const filename =
      new Date().getTime() + "_file." + fileInput.name.split(".").pop();

    const storageRef = ref(this.storage, `${folderName}/${filename}`);
    try {
      await uploadBytes(storageRef, fileInput);
      return Promise.resolve(filename);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export default StorageService;
