import {
  addDoc,
  collection,
  doc,
  getDocs,
  setDoc,
  query,
  where,
  getDoc,
} from "firebase/firestore";
import { app, db } from "../firebase/firebase";

/**
 * @author Fani Keorapetse
 * @private
 * @description Firebase crud defines the basic create,read,update,delete operations based on firebase v9
 */

// const app = initializeApp(firebaseConfig);
// const db = getFirestore(app);

class FirebaseCrud {
  collectionName;
  _docs = [];
  docRef;

  constructor(collectionName) {
    this.collectionName = collectionName;
  }

  async addDocument(payload, addedBy, key = null) {
    console.log(payload, !key);

    if (!key) {
      this.docRef = await addDoc(collection(db, this.collectionName), {
        ...payload,
        addedBy,
      });
    } else {
      this.docRef = doc(db, this.collectionName, key);
      console.log("COLLECTION REF 👌👌👌👌", this.docRef);
      await setDoc(this.docRef, { ...payload, addedBy });
    }
  }

  async documentExist(key, value) {
    // prettier-ignore
    const q = query(collection(db, this.collectionName), where(key, "==", value))
    const querySnapshot = await getDocs(q);
    // return querySnapshot.docs.length > 0;
    if (querySnapshot.docs.length > 0) {
      return querySnapshot.docs[0].data();
    }
    return {};
  }

  async readDocument() {
    const snapshot = await getDocs(collection(db, this.collectionName));
    this._docs = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

    return this._docs;
  }

  async readDocumentWithId(id) {
    const snapshot = await getDoc(doc(db, this.collectionName, id));
    if (snapshot.exists()) {
      return snapshot.data();
    }
    return null;
  }

  async updateDocument(payload, key, updatedBy) {
    this.docRef = doc(db, this.collectionName, key);
    // const q = query(this.docRef, where(key, "==", value));
    await setDoc(this.docRef, {
      ...payload,
      updatedBy,
    });
  }

  deleteDocument(key) {}
}

export default FirebaseCrud;
