import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { globalActions } from "../../slices/globalSlice";

const Toast = () => {
  const toast = useSelector((state) => state.global.toast);
  const dispatch = useDispatch();

  useEffect(() => {
    if (toast) {
      let timeoutId;

      if (toast.showToast) {
        timeoutId = setTimeout(() => {
          dispatch(
            globalActions.setToast({
              showToast: false,
              toastMessage: "",
              timer: 3000,
            })
          );
        }, toast.timer);
      }

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [toast]);

  const handleClick = () => {
    dispatch(
      globalActions.setToast({
        showToast: false,
        toastMessage: "",
        timer: 3000,
      })
    );
  };

  return (
    <div>
      {/* {JSON.stringify(toast)} */}
      {toast ? (
        <>
          <style>
            {`
          #snackbar {
            visibility: hidden;
            min-width: 250px;
            margin-left: -125px;
            background-color: #333;
            color: #fff;
            text-align: center;
            border-radius: 2px;
            padding: 16px;
            position: fixed;
            z-index: 1;
            left: 50%;
            bottom: 30px;
            font-size: 17px;
          }

          #snackbar.show {
            visibility: visible;
            -webkit-animation: fadein 0.5s, fadeout 0.5s ${toast.timer / 1000}s;
            animation: fadein 0.5s, fadeout 0.5s ${toast.timer / 1000}s;
          }

          @-webkit-keyframes fadein {
            from { bottom: 0; opacity: 0; } 
            to { bottom: 30px; opacity: 1; }
          }

          @keyframes fadein {
            from { bottom: 0; opacity: 0; }
            to { bottom: 30px; opacity: 1; }
          }

          @-webkit-keyframes fadeout {
            from { bottom: 30px; opacity: 1; } 
            to { bottom: 0; opacity: 0; }
          }

          @keyframes fadeout {
            from { bottom: 30px; opacity: 1; }
            to { bottom: 0; opacity: 0; }
          }
        `}
          </style>
          <div id="snackbar" className={toast.showToast ? "show" : ""}>
            {toast.toastMessage}
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Toast;
