import { createSlice } from "@reduxjs/toolkit";

import UsersService from "../services/usersService";
const usersService = new UsersService();

const initialState = {
  users: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
  },
});

export const { setUsers } = userSlice.actions;

export const fetchUsersAsync = async (dispatch) => {
  const users = await usersService.readUsers();
  dispatch(setUsers(users));
};

export default userSlice;
