import React, { useState, useEffect, useRef } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const DropdownButton = ({
  options,
  closeChat,
  emailChat,
  downloadChatAsPdf,
  reportChatUser,
  convertToCase,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const myDropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);

    if (option.label.toLowerCase() == "download") {
      downloadChatAsPdf();
    } else if (option.value.toLowerCase() == "closechat") {
      closeChat();
    } else if (option.value.toLowerCase() == "email") {
      emailChat();
    } else if (option.value.toLowerCase() == "blockuser") {
      reportChatUser();
    } else if (option.value.toLowerCase() == "converttocase") {
      convertToCase();
    }

    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (
      myDropdownRef.current &&
      !myDropdownRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });

  return (
    <div className="my-dropdown" ref={myDropdownRef}>
      <button className="my-dropdown-button" onClick={toggleDropdown}>
        {/* {selectedOption ? selectedOption.label : */}
        <MoreHorizIcon />
      </button>
      {isOpen && (
        <ul className="my-dropdown-menu">
          {options.map((option) => (
            <li
              key={option.value}
              className="my-dropdown-option"
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropdownButton;
