import FirebaseCrud from "./firebaseCrud";

const COLLECTION_NAME = "users";

class UsersService extends FirebaseCrud {
  user;
  constructor() {
    super(COLLECTION_NAME);
    this.collectionName = COLLECTION_NAME;
  }
  async addUser(email, date_created, role, department, addedBy) {
    const payload = { email, date_created, role, department };
    const existingPayload = await this.documentExist("email", email);

    if (Object.keys(existingPayload).length > 0) {
      return existingPayload;
    }
    await this.addDocument(payload, addedBy, email);
    return payload;
  }
  async readUsers() {
    const users = await this.readDocument();
    return users;
  }
  async modifyUser(email, newUserInfo, updatedBy) {
    await this.updateDocument(newUserInfo, email, updatedBy);
  }
  _getRoles() {}
  modifyUserRole(email, role) {}
}

export default UsersService;
