import React, { useState } from "react";
import MyEditor from "../../Editor"
import DragAndDrop from "../../DragAndDropBox"
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";

const ResolutionTab = () => {
    const [files, setFiles] = useState([]);

    const caseStatus = [
        { value: "open", label: "Open" },
        { value: "closed", label: "closed" },
        { value: "canceled", label: "Canceled" },
        { value: "onhold", label: "On Hold" },
        { value: "resolved", label: "Resolved" },
    ];

    const [selectedStatus, setSelectedStatus] = useState(caseStatus[0]);

    const handleStatusChange = (event, selected) => {
        setSelectedStatus(selected);
    };

    return(
        <div className="resolution-tab">
            <h5>Resolution</h5>
            <form action="" method="post">
                <MyEditor />
                
                <div className="row mt-4">
                    <div className="col-4">
                        <label htmlFor="">Status</label>
                        <Autocomplete
                            value={selectedStatus}
                            onChange={handleStatusChange}
                            options={caseStatus}
                            getOptionLabel={(caseStatus) => caseStatus.label}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                placeholder="-- Select Status --"
                                variant="outlined"
                                />
                            )}
                        />
                    </div>
                </div>

                <h5 className="attachments-header">Attachments</h5>
                <DragAndDrop files={files} setFiles={setFiles}/>

                <div className="btns-container">
                    <button type="button" className="main-btn">Save</button>
                    <button type="button" className="no-border-btn">Cancel</button>
                </div>
            </form>
        </div>
    )
}

export default ResolutionTab;