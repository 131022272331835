import React from "react";
import { useDispatch, useSelector } from "react-redux";
import WindowIcon from "@mui/icons-material/Window";
import { authActions, loginAsyncWithMicrosoft } from "../slices/AuthSlice";
import { useNavigate } from "react-router-dom";
import UsersService from "../services/usersService";

const NORMAL_USER_ID = "FBDLZWsvk56hMtwkLpui";

const Login = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const userService = new UsersService();

  const handleLogin = async () => {
    console.log("Starting login mechanism.");
    const res = await dispatch(loginAsyncWithMicrosoft());
    const user = JSON.parse(res.payload);
    // const _user = await userService.addUser(
    //   user.user.email,
    //   Date.now(),
    //   NORMAL_USER_ID,
    //   "ICT",
    //   null
    // );

    //ToBe fixed
    const NORMAL_USER_ID = "FBDLZWsvk56hMtwkLpui";
    const _user = {
      email: user.user.email,
      date_created: Date.now(),
      role: NORMAL_USER_ID,
      department: "ICT",
    };
    dispatch(authActions.addUserInfo(_user));
    navigate("/cases");
  };

  return (
    <div>
      <div id="welcome_page">
        <div className="welcome-nav">
          <div className="logo">
            <span>
              <span></span>
            </span>
            <h4>Service Desk</h4>
          </div>
          <div className="nav-menu">
            <button type="button">Contact</button>
            <span className="v-divider"></span>
            <button type="button">User Guide</button>
          </div>
        </div>
        <div className="welcome-centent">
          <div className="hero-part">
            <h1>
              Say Goodbye to slow support &amp; hello <br />
              to a Real-time <span>knowledge base system.</span>
            </h1>
            <p>
              Manages tickets, incidents and service requests, as well as user
              communication via Web chat, Whatsapp and other channels.
            </p>
            <button className="main-btn" onClick={handleLogin}>
              <WindowIcon /> <span>Sign in with Microsoft</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
