import FirebaseCrud from "./firebaseCrud";
const CONFIGS_NAME = "configs";
class ConfigsService extends FirebaseCrud {
  constructor() {
    super(CONFIGS_NAME);
    this.collectionName = CONFIGS_NAME;
  }

  async getConfigs() {
    const configs = await this.readDocumentWithId(CONFIGS_NAME);
    return configs;
  }
}

export default ConfigsService;
