import React, { Component } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const MyEditor = ({ comment, setComment }) => {
  const handleChange = (content, delta, source, editor) => {
    // event.preventDefault();
    setComment(content);
  };

  return (
    <div>
      <ReactQuill
        value={comment}
        onChange={handleChange}
        placeholder="Enter text here"
      />
    </div>
  );
};

export default MyEditor;
