// src/components/TodoList.js
import React, { useEffect } from "react";
import Sidebar from "../components/Sidebar";
import TopNav from "../components/TopNav";
import { useDispatch, useSelector } from "react-redux";
import { useCollection } from "../hooks/useCollections";
import { Outlet, useLocation } from "react-router-dom";
import { globalActions } from "../slices/globalSlice";
import UserDialog from "../components/UserDialog";
import Toast from "../components/common/Toast";

const HomePage = () => {
  const tab = useSelector((state) => state.global.tab);
  const toast = useSelector((state) => state.global.toast);

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const currentUrl = location.pathname.split("/")[1];
    dispatch(globalActions.changeTab(currentUrl));
  }, []);

  return (
    <div>
      <div id="home_page">
        <UserDialog />
        {/* <Sidebar /> */}
        <div className="main-side">
          <TopNav />
          <div className="inner-container">
            <Outlet />
          </div>

          {toast && toast.showToast ? <Toast /> : ""}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
