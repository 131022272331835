import React from "react";
import CasesTable from "../../components/cases/CasesTable";
import { useNavigate } from "react-router-dom";

const CasesList = () => {
  const navigate = useNavigate();

  const onHandlePageChange = (pageName) => {
    navigate("" + pageName);
  };

  return (
    <>
      <div id="client_page">
        <h4 className="page-title">Client Portal</h4>
        <div className="stats-container">
          <div className="stats-card shadow-sm">
            <h4>30</h4>
            <span>Total Cases</span>
          </div>
          <div className="stats-card shadow-sm">
            <h4>23</h4>
            <span>Total Closed</span>
          </div>
          <div></div>
          <div className="ms-auto">
            <button
              className="main-btn"
              onClick={() => onHandlePageChange("create_case")}
            >
              Create Case
            </button>
          </div>
        </div>
        <div className="case-table">
          <CasesTable />
        </div>
      </div>
    </>
  );
};

export default CasesList;
