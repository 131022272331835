import React from "react";
import { useSelector } from "react-redux";

const AttachmentsTab = () => {
    const activeCase = useSelector((state) => state.cases.activeCase);
    return(
        <div className="attachments-tab">
            <h5>Attachments</h5>
            <div className="main-documents">
                <h4>Main Attachments</h4>
                <div className="f">

                </div>
            </div>
            <div className="comments-documents">
                <h4>Comments Attachments</h4>
            </div>
        </div>
    )
}

export default AttachmentsTab;