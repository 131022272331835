import React, { useEffect, useState, useRef } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

import { useSelector, useDispatch } from "react-redux";
import {
  sendWhatsAppMessage,
  updateChat,
  updateChatStatusApi,
} from "../../slices/api/chatsAPI";
import StorageService from "../../services/storageService";
import { createCase as createCaseApi } from "../../slices/api/casesApi";
import {
  setActiveChat,
  setIdForChatCurrentlyAttending,
} from "../../slices/ChatSlice";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import moment from "moment";
import Toast from "../common/Toast";
import Constants from "../../constants/Constants";
import DropDown from "../DropDown";
import ConversationToDownLoadAsPdf from "../common/ConversationToDownloadAsPdf";
import { sendEmailApi } from "../../services/commonService";

const options = [
  { label: "Email", value: "Email" },
  { label: "Convert to Case", value: "ConvertToCase" },
  { label: "Close Chat", value: "CloseChat" },
  { label: "Download", value: "Download" },
  { label: "Block User", value: "BlockUser" },
];

const MessageView = () => {
  const storageService = new StorageService();
  const [files, setFiles] = useState([]);
  const downloadConversationAsPdfRef = useRef(null);
  const [chatDataToDownload, setChatDataToDownload] = useState(null);
  const [message, setMessage] = useState("");
  const chat = useSelector((state) => state.chat);
  const authUser = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSendBtnClick = (event) => {
    event.preventDefault();

    if (message.trim() === "") {
      return;
    }

    const messageBody = message;
    setMessage("");
    updateChat(chat.activeChat.id, messageBody);
    if (chat.activeChat.Channel == Constants.channels.WHATSAPP) {
      sendWhatsAppMessage(chat.activeChat.id, messageBody);
    }
  };

  useEffect(() => {
    if (chat.activeChat) {
      const updatedActiveChat = chat.conversations.find(
        (conversation) => conversation.id == chat.activeChat.id
      );

      if (updatedActiveChat)
        setChatDataToDownload({
          clientNumber:
            updatedActiveChat.Channel == Constants.channels.WHATSAPP
              ? `+${updatedActiveChat.id}`
              : updatedActiveChat.Name,
          agentName: "Agent",
          chatList: updatedActiveChat.Messages,
        });

      dispatch(setActiveChat(updatedActiveChat));
    }
  }, [chat]);

  // Function to convert chat history to text part format
  function generateTextPartFormat(chatHistory) {
    let textPartFormat = "";
    chatHistory.Messages.forEach((message, index) => {
      const { Sender, MessageContent, DateCreated } = message;
      textPartFormat += `${
        index + 1
      }. ${Sender} - ${MessageContent} (Sent: ${DateCreated})\n`;
    });
    return textPartFormat;
  }

  // Function to convert chat history to HTML part format
  function generateHtmlPartFormat(chatHistory) {
    let htmlPartFormat = "<div>\n";
    chatHistory.Messages.forEach((message, index) => {
      const { Sender, MessageContent, DateCreated } = message;

      htmlPartFormat += `<div class="chat-message">\n`;
      htmlPartFormat += `<span class="chat-sender"><b>${Sender}:</span></b> ${MessageContent}\n`;
      htmlPartFormat += `<small><span class="chat-timestamp">${moment
        .utc(new Date(DateCreated), "MM/DD/YYYY h:mm A")
        .format("HH:mm DD MMM, YYYY")}</span></small><br>`;
      htmlPartFormat += `</div><br>`;
    });

    htmlPartFormat += "</div><br>";
    return htmlPartFormat;
  }

  const sendEmail = async (activeChat) => {
    const payload = {
      recipientEmailAddresses: ["fezekileplaatyi@myplusplus.com"],
      templateData: {
        caseNumber: "#mYSD-2020-12", // Replace with the case number you want to include in the subject
        chatDataInTextPartFormat: generateTextPartFormat(activeChat),
        chatDataInHtmlPartFormat: generateHtmlPartFormat(activeChat),
      },
      sourceEmail: Constants.sourceEmailList.NO_REPLY,
      templateName: "case_chat_history_v4",
    };

    try {
      const sendEmailResponse = await sendEmailApi(payload);
      console.log("Success: ", sendEmailResponse);
    } catch (error) {
      console.error("An error occured while sending email: ", error);
    }
  };

  const downloadChatAsPdf = () => {
    html2canvas(downloadConversationAsPdfRef.current, {
      onclone: function (clonedDoc) {
        // I made the div hidden and here I am changing it to visible
        clonedDoc.getElementById("myServiceDeskPdfTable").style.display =
          "block";
      },
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 10, 10);
      pdf.save(`chat_${new Date().getTime()}.pdf`);
    });
  };

  const closeChat = async () => {
    await updateChatStatusApi(chat.activeChat.id, Constants.chatStages.CLOSED);
    dispatch(setActiveChat(null));
  };

  const emailChat = async () => {
    console.log(chat.activeChat);
    await sendEmail(chat.activeChat);
    alert("Emailed the Chat.");
  };

  const convertToCase = async () => {
    let newCaseData = {
      assignedTo: [authUser.user.email],
      priority: Constants.priorities.HIGH.Label,
      status: Constants.caseStatuses.OPEN,
      caseRequester: chat.activeChat.Email,
      category: chat.activeChat.ParentCategory,
      subCategory: chat.activeChat.Category,
      caseSummary: chat.activeChat.Messages[0],
      caseDescription:
        "Please read through the WhatsApp Chat attached on Origin Chat.",
      dateCreated: new Date(),
      comments: [],
      originChatMessages: chat.activeChat.Messages,
    };

    newCaseData.attachments = [];

    for (var i = 0; i < files.length; i++) {
      try {
        const uploadFileResult = await storageService.uploadFile(
          files[i],
          Constants.typeOfService.CASES
        );

        newCaseData.attachments.push({
          attachmentUrl: uploadFileResult,
          messageId: "main",
          dateUploaded: new Date().toString(),
        });
      } catch (error) {
        alert("Error occured while uploading");
        console.log(error);
      }
    }

    try {
      await Promise.all([createCaseApi(newCaseData), closeChat()]);
      alert("Successfully converted the Chat to Case!");
      // Add any additional logic you need to handle case creation success here
      // formRef.current.reset();
    } catch (error) {
      console.log(error);
      alert("Failed to convert Case to Chat.");
      // Add any error handling logic you need here
    }
  };

  const reportChatUser = async () => {
    const reason = "User is rude."; //ToDo ask from user
    updateChatStatusApi(
      chat.activeChat.id,
      Constants.chatStages.BLOCKED,
      reason
    );

    const payload = {
      recipientEmailAddresses: ["fezekileplaatyi@myplusplus.com"],
      templateData: {
        identifier:
          chat.activeChat.Channel == "WHATSAPP"
            ? `+${chat.activeChat.id}`
            : chat.activeChat.Name,
        channel: chat.activeChat.Channel,
        reason: reason, //to ask before blocking
        blockerName: authUser.user.fullname,
      },
      sourceEmail: Constants.sourceEmailList.NO_REPLY,
      templateName: "user_blocked_admin_notifier_template_v2",
    };
    await sendEmailApi(payload);
    alert("User blocked.");
    dispatch(setIdForChatCurrentlyAttending(null));
  };

  const handledAttendChatClick = (event, chatId) => {
    event.preventDefault();
    if (chat.idForChatCurrentlyAttending) {
      alert("You are only allowed to attend one chat at the time.");
    } else {
      dispatch(setIdForChatCurrentlyAttending(chatId));
    }
  };

  const checkIfChatIsOneAttended = () => {
    return (
      !chat.idForChatCurrentlyAttending ||
      chat.idForChatCurrentlyAttending !== chat.activeChat.id
    );
  };

  return (
    <>
      <div className="message-view">
        <div style={{ display: "none" }} id="myServiceDeskPdfTable">
          <div id="divToPrint" ref={downloadConversationAsPdfRef}>
            <ConversationToDownLoadAsPdf
              chatDataToDownload={chatDataToDownload}
            />
          </div>
        </div>
        <div className="inner-container">
          {chat && chat.activeChat ? (
            <>
              <div className="header-part">
                <h4>
                  {chat.activeChat.Channel == Constants.channels.WHATSAPP
                    ? `+${chat.activeChat.id}`
                    : chat.activeChat.Name}
                </h4>
                <div className="action-btn">
                  {!chat.idForChatCurrentlyAttending &&
                  chat.activeChat.Status != Constants.chatStages.CLOSED ? (
                    <button
                      type="button"
                      className="attend-chat"
                      onClick={(event) =>
                        handledAttendChatClick(event, chat.activeChat.id)
                      }
                    >
                      Attend Chat
                    </button>
                  ) : (
                    <></>
                  )}
                  <DropDown
                    options={options}
                    closeChat={closeChat}
                    downloadChatAsPdf={downloadChatAsPdf}
                    emailChat={emailChat}
                    convertToCase={convertToCase}
                    reportChatUser={reportChatUser}
                  />
                </div>
              </div>
              <div className="messages">
                {chat.activeChat.Messages.map((conversation) => {
                  return (
                    <div key={conversation.id}>
                      <div className="message-container">
                        <div
                          className={`message-wrapper ${
                            conversation.Sender === "CLIENT"
                              ? "received"
                              : "outgoing"
                          }`}
                        >
                          <div className="message">
                            {conversation.MessageContent}
                          </div>
                          <span className="time">
                            {moment
                              .utc(
                                new Date(conversation.DateCreated),
                                "MM/DD/YYYY h:mm A"
                              )
                              .format("HH:mm DD MMM, YYYY")}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        {chat && chat.activeChat ? (
          <div className="write-message-container">
            <div className="write-message">
              <textarea
                name="chat_message"
                id="chat_message"
                placeholder="Type Message..."
                value={message}
                onChange={handleInputChange}
                disabled={checkIfChatIsOneAttended()}
              ></textarea>
              <div className="action-btns">
                <button type="button">
                  <EmojiEmotionsIcon />
                </button>
                <button type="button">
                  <AttachFileIcon />
                </button>
                <span className="v-divider"></span>
                <button
                  type="button"
                  className="send-btn"
                  disabled={checkIfChatIsOneAttended()}
                  onClick={handleSendBtnClick}
                >
                  <SendIcon />
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default MessageView;
