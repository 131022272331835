import {
  createBrowserRouter,
  Route,
  redirect,
  createRoutesFromElements,
} from "react-router-dom";
import HomePage from "../pages/Home";
import Login from "../pages/Login";
import Dashboard from "../pages/dashboard/dashboard";
import Chats from "../pages/chats/chats";
import Center from "../pages/center/center";
import Faqs from "../pages/faqs/faqs";
import Cases from "../pages/cases/cases";
import CasesList from "../components/cases/CasesList";
import CreateCase from "../components/cases/CreateCase";
import SingleCaseView from "../components/cases/SingleCaseView";
import Survey from "../pages/survey/survey";

const loader = async () => {
  const isAuthinticated = localStorage.getItem("isAuthinticated");
  console.log("Is the user Authinticated: 🔑🔑🔑🔑", isAuthinticated);
  if (!isAuthinticated) {
    return redirect("/");
  }
  return null;
};

const router = createBrowserRouter(
  createRoutesFromElements([
    <Route key="/login" path="/" element={<Login />} />,
    <Route key="survey" path="survey" element={<Survey />} />,

    <Route
      path="/"
      element={<HomePage />}
      children={[
        <Route
          key="dashboard"
          index
          path="dashboard"
          element={<Dashboard />}
          loader={loader}
        />,
        <Route
          path="cases"
          element={<Cases />}
          children={[
            <Route
              key="cases_list"
              index
              path=""
              element={<CasesList />}
              loader={loader}
            />,
            <Route
              key="create_case"
              path="create_case"
              element={<CreateCase />}
              loader={loader}
            />,
            <Route
              key="single_case"
              path="single_case/:caseId"
              element={<SingleCaseView />}
              loader={loader}
            />,
          ]}
        />,
        <Route key="chats" path="chats" element={<Chats />} loader={loader} />,
        <Route
          key="center"
          path="call_center"
          element={<Center />}
          loader={loader}
        />,
        <Route key="faqs" path="faqs" element={<Faqs />} loader={loader} />,
      ]}
    />,
  ])
);

export default router;
