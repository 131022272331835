import React, { useState } from "react";

const formatFileSize = (size) => {
  if (size < 1024) {
    return `${size} b`;
  } else if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)} kb`;
  } else {
    return `${(size / (1024 * 1024)).toFixed(2)} mb`;
  }
};

const DragAndDropBox = ({ files, setFiles }) => {
  const handleFileDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
  };

  const handleFileSelect = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleRemoveFile = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  return (
    <div
      className="drag-drop-box"
      onDrop={handleFileDrop}
      onDragOver={(e) => e.preventDefault()}
    >
      <input
        type="file"
        id="file-input"
        multiple
        style={{ display: "none" }}
        onChange={handleFileSelect}
      />

      <div className="drop-area">
        {files.length > 0 && (
          <div className="file-list">
            {files.map((file) => (
              <div key={file.name} className="file-item shadow-sm">
                <span className="file-name">{file.name}</span>
                <span className="file-size">{formatFileSize(file.size)}</span>
                <button
                  onClick={() => handleRemoveFile(file.name)}
                  className="remove-btn"
                >
                  x
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="drag-text">
          <span>Drag and drop files here or </span>
          <label htmlFor="file-input">
            <span className="browse-link">browse</span>
          </label>
          .
        </div>
      </div>
    </div>
  );
};

export default DragAndDropBox;
