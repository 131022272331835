import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCjU3Txy0QyXt3Sx8PAD9wtCbDyJLOWqcY",
  authDomain: "my-service-desk-a8855.firebaseapp.com",
  projectId: "my-service-desk-a8855",
  storageBucket: "my-service-desk-a8855.appspot.com",
  messagingSenderId: "629480047972",
  appId: "1:629480047972:web:d44a70d87336b99393e671",
  measurementId: "G-3HZVVTL6C1",
};

export const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
