import axios from "axios";
import Constants from "../constants/Constants";
const EMAIL_SERVICE_ENDPOINT = Constants.endpoints.EMAIL_SERVICE_ENDPOINT;
const headers = {
  "Content-Type": "application/json",
};

export const sendEmailApi = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(EMAIL_SERVICE_ENDPOINT, payload, { headers })
      .then((res) => {
        resolve("ok");
      })
      .catch((error) => {
        console.log("Error occured: ", error);
        reject(error);
      });
  });
};
