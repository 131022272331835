import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import MyEditor from "../Editor";
import DragAndDropBox from "../DragAndDropBox";
import { createCase as createCaseApi } from "../../slices/api/casesApi";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import StorageService from "../../services/storageService";
import Constants from "../../constants/Constants";
import { useDispatch, useSelector } from "react-redux";
import { fetchConfigsAsync } from "../../slices/ConfigSlice";
import { fetchRequestersAsync } from "../../slices/RequesterSlice";
import { fetchUsersAsync } from "../../slices/UserSlice";

const CreateCase = () => {
  const dispatch = useDispatch();
  const configsState = useSelector((state) => state.configs);
  const requestersState = useSelector((state) => state.requester);
  const usersState = useSelector((state) => state.user);
  const formRef = useRef(null);
  const storageService = new StorageService();
  const [files, setFiles] = useState([]);
  const [caseSummary, setCaseSummary] = useState("");
  const [caseDescription, setCaseDescription] = useState("");
  const [caseStatuses, setCaseStatuses] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [impacts, setImpacts] = useState([]);
  const [assignees, setAssignees] = useState([]);
  const [requesters, setRequesters] = useState([]);
  const [selectedRequester, setSelectedRequester] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [selectedImpact, setSelectedImpact] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchConfigsAsync(dispatch);
    fetchRequestersAsync(dispatch);
    fetchUsersAsync(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (configsState && configsState.caseStatuses.length > 0) {
      setCaseStatuses(configsState.caseStatuses);
      if (!selectedStatus) {
        setSelectedStatus(configsState.caseStatuses[0]);
      }
    }

    if (configsState && configsState.priorities.length > 0) {
      setPriorities(configsState.priorities);
    }

    if (configsState && configsState.impacts.length > 0) {
      setImpacts(configsState.impacts);
    }

    if (requestersState && requestersState.requesters.length > 0) {
      setRequesters(requestersState.requesters);
    }

    if (usersState) {
      let usersToAssign = [];
      if (usersState.users.length > 0) {
        usersState.users.forEach((user) => {
          let userDuplicated = false;
          if (usersToAssign.length > 0) {
            userDuplicated =
              usersToAssign.filter(
                (u) => u.label === user.fullname || u.value === user.email
              ).length > 0;
          }
          if (!userDuplicated) {
            usersToAssign.push({
              value: user.email ?? "",
              label: user.fullname ?? "",
            });
          }
        });
      }

      setAssignees(usersToAssign);
    }
  }, [configsState, requestersState, usersState]);

  const category = [
    { value: "web", label: "Web" },
    { value: "software", label: "Software" },
    { value: "hr", label: "Human Resource" },
    { value: "wifi", label: "WiFi" },
    { value: "pabx", label: "PABX" },
  ];

  const subcategory = [
    { value: "mainwebsite", label: "Organization Website" },
    { value: "sharepoint", label: "Microsoft Sharepoint" },
    { value: "servicedesk", label: "Service Desk" },
    { value: "everythingWeb", label: "Everything About Web" },
  ];

  const handleRequesterChange = (event, selected) => {
    setSelectedRequester(selected);
  };

  const handlePriorityChange = (event, selected) => {
    setSelectedPriority(selected);
  };

  const handleImpactChange = (event, selected) => {
    setSelectedImpact(selected);
  };

  const handleStatusChange = (event, selected) => {
    setSelectedStatus(selected);
  };

  const handleAssigneeChange = (event, selected) => {
    setSelectedAssignees(selected);
  };

  const handleCategoryChange = (event, selected) => {
    setSelectedCategory(selected);
  };

  const handleSubCategoryChange = (event, selected) => {
    setSelectedSubCategory(selected);
  };

  const handleSubmitCase = async (event) => {
    event.preventDefault();

    const newCaseData = {
      caseSummary: document.getElementById("case_summary").value,
      caseDescription: caseDescription,
      category: selectedCategory.value,
      assignedTo: selectedAssignees.map((data) => data.value),
      status: selectedStatus.value,
      caseRequester: selectedRequester.value,
      priority: selectedPriority.value,
      impact: selectedImpact.value,
      subCategory: selectedSubCategory.value,
      dateCreated: new Date().toString(),
      comments: [],
    };

    newCaseData.attachments = [];

    for (var i = 0; i < files.length; i++) {
      try {
        const uploadFileResult = await storageService.uploadFile(
          files[i],
          Constants.typeOfService.CASES
        );

        newCaseData.attachments.push({
          attachmentUrl: uploadFileResult,
          messageId: "main",
          dateUploaded: new Date().toString(),
        });
      } catch (error) {
        alert("Error occured while uploading");
        console.log(error);
      }
    }

    try {
      await createCaseApi(newCaseData);

      alert("Case created successfully!");
      // Add any additional logic you need to handle case creation success here
      formRef.current.reset();
    } catch (error) {
      console.log(error);
      alert("Failed to create case!");
      // Add any error handling logic you need here
    }
  };

  return (
    <>
      <div id="create_new_case">
        <div className="header-part">
          <button
            type="button"
            className="back-btn"
            onClick={() => navigate(-1)}
          >
            <ChevronLeftIcon /> Back
          </button>
          <h2>New Case</h2>
        </div>

        <div className="my-form-container">
          <form ref={formRef} id="newCaseForm" method="post">
            <div className="inputs-container row gy-3">
              <div className="col-4">
                <label htmlFor="" className="required-field">
                  Requester Name
                </label>
                <Autocomplete
                  value={selectedRequester}
                  onChange={handleRequesterChange}
                  options={requesters}
                  getOptionLabel={(requesters) => requesters.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="-- Select Requester --"
                      variant="outlined"
                      required
                    />
                  )}
                />
              </div>
              <div className="col-4">
                <label htmlFor="" className="required-field">
                  Priority
                </label>
                <Autocomplete
                  value={selectedPriority}
                  onChange={handlePriorityChange}
                  options={priorities}
                  getOptionLabel={(priority) => priority.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="-- Select Priority --"
                      variant="outlined"
                      required
                    />
                  )}
                />
              </div>
              <div className="col-4">
                <label htmlFor="" className="required-field">
                  Impact
                </label>
                <Autocomplete
                  value={selectedImpact}
                  onChange={handleImpactChange}
                  options={impacts}
                  getOptionLabel={(impact) => impact.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="-- Select Impact --"
                      variant="outlined"
                      required
                    />
                  )}
                />
              </div>

              <div className="col-4">
                <label htmlFor="">Status</label>
                <Autocomplete
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  options={caseStatuses}
                  getOptionLabel={(caseStatus) => caseStatus.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="-- Select Status --"
                      variant="outlined"
                    />
                  )}
                />
              </div>
            </div>
            <h4>Assignee Details</h4>
            <div className="inputs-container row">
              <div className="col-8 row gb-3">
                <div className="col-6">
                  <label htmlFor="" className="required-field">
                    Category
                  </label>
                  <Autocomplete
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    options={category}
                    getOptionLabel={(category) => category.label}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="-- Select Category --"
                        variant="outlined"
                        required
                      />
                    )}
                  />
                </div>
                <div className="col-6">
                  <label htmlFor="">Sub-Category</label>
                  <Autocomplete
                    value={selectedSubCategory}
                    onChange={handleSubCategoryChange}
                    options={subcategory}
                    getOptionLabel={(subcategory) => subcategory.label}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="-- Select Sub-Category --"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-4">
                <label htmlFor="">Assignee</label>
                <Autocomplete
                  multiple
                  limitTags={5}
                  onChange={handleAssigneeChange}
                  options={assignees}
                  getOptionLabel={(assignee) => assignee.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="-- Select Assignees --"
                      variant="outlined"
                    />
                  )}
                />
              </div>
            </div>
            <div className="inputs-container">
              <h4>Case Details</h4>
              <div>
                <label htmlFor="case_summary" className="required-field">
                  Case Summary
                </label>
                <input
                  type="text"
                  name="case_summary"
                  id="case_summary"
                  className="case-summary"
                  required
                  maxLength={100}
                  placeholder="Case Summary"
                  value={caseSummary}
                  onChange={(e) => setCaseSummary(e.target.value)}
                />
                <label htmlFor="">Case Description</label>
                <MyEditor
                  comment={caseDescription}
                  setComment={setCaseDescription}
                />
              </div>
            </div>
            <div className="inputs-container">
              <h4>Attachments</h4>
              <div>
                <DragAndDropBox files={files} setFiles={setFiles} />
              </div>
            </div>

            <div className="btn-container">
              <button type="button" className="cancel">
                Cancel
              </button>
              <button
                type="submit"
                className="main-btn"
                onClick={handleSubmitCase}
              >
                Create Case
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateCase;
