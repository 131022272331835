import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";

const DetailsTab = () => {
    const activeCase = useSelector((state) => state.cases.activeCase);

    const formatFileSize = (size) => {
        if (size < 1024) {
          return `${size} b`;
        } else if (size < 1024 * 1024) {
          return `${(size / 1024).toFixed(2)} kb`;
        } else {
          return `${(size / (1024 * 1024)).toFixed(2)} mb`;
        }
    };

    const getExtension = (filename) => {
        return filename.split('.').pop()
    }
    return(
        <div className="details-tab">
            <h5>Request details</h5>

            <div className="details-container">
                <div className="row">
                    <div className="col-6">
                        <table>
                            <tbody>
                                <tr>
                                    <td>Request type</td>
                                    <td>Normal</td>
                                </tr>
                                <tr>
                                    <td>Status</td>
                                    <td>{activeCase.status}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-6">
                        <table>
                            <tbody>
                                <tr>
                                    <td>Impact</td>
                                    <td>{activeCase.impact}</td>
                                </tr>
                                <tr>
                                    <td>Priority</td>
                                    <td>{activeCase.priority}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="details-container">
                <div className="row">
                    <div className="col-6">
                        <table>
                            <tbody>
                                <tr>
                                    <td>Requester Name</td>
                                    <td>{activeCase.caseRequester}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="details-container">
                <div className="row">
                    <div className="col-6">
                        <table>
                            <tbody>
                                <tr>
                                    <td>Category</td>
                                    <td>{activeCase.category}</td>
                                </tr>
                                <tr>
                                    <td>Sub-Category</td>
                                    <td>{activeCase.subCategory}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-6">
                        <table>
                            <tbody>
                                <tr>
                                    <td>Assignee</td>
                                    {activeCase.assignedTo.map((assignee) => (
                                        <td>{ assignee }</td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="details-container">
                <div className="row">
                    <div className="col-6">
                        <table>
                            <tbody>
                                <tr>
                                    <td>Date Created</td>
                                    <td>{moment.utc(new Date(activeCase.dateCreated), "MM/DD/YYYY h:mm A").format("MMM DD, YYYY HH:mm")}</td>
                                </tr>
                                <tr>
                                    <td>Date Updated</td>
                                    <td>{moment.utc(new Date(activeCase.dateUpdated), "MM/DD/YYYY h:mm A").format("MMM DD, YYYY HH:mm")}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-6">
                        <table>
                            <tbody>
                                <tr>
                                    <td>Reported By</td>
                                    <td>{activeCase.reportedBy}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="details-container">
                <h5>Description</h5>
                <div dangerouslySetInnerHTML={{ __html: activeCase.caseDescription }}></div>
            </div>
            <div className="details-container">
                <h5>Main Attachments</h5>
                {activeCase.attachments.map((attachment) =>(
                    <div key={attachment.messageId} className="file-item shadow-sm">
                        <span className="file-name">{attachment.attachmentUrl}</span>
                        {/* <span className="file-size">{formatFileSize(attachment.size)}</span> */}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default DetailsTab;