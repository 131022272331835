import React, { useState, useEffect } from "react";
import {
  fetchFAQData,
  addFAQToFirestore,
  deleteFAQFromFirestore,
  updateFAQInFirestore,
} from "../../slices/api/fqaApi";
import MyEditor from "../../components/Editor";

const FQA = () => {
  const [faqData, setFaqData] = useState([]);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [faqId, setFaqId] = useState("");
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchFAQData();
      setFaqData(data);
    };

    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (editMode) {
        await updateFAQInFirestore(faqId, question, answer);
      } else {
        await addFAQToFirestore(question, answer);
      }

      setQuestion("");
      setAnswer("");
      setEditMode(false);
      setFaqId("");
      setShowForm(false);
    } catch (error) {
      console.error("Error adding/updating FAQ:", error);
    }
  };

  const handleEdit = (faqId) => {
    const selectedFaq = faqData.find((faq) => faq.id === faqId);
    if (selectedFaq) {
      setQuestion(selectedFaq.question);
      setAnswer(selectedFaq.answer);
      setFaqId(faqId);
      setEditMode(true);
      setShowForm(true);
    }
  };

  const handleDelete = async (faqId) => {
    try {
      await deleteFAQFromFirestore(faqId);
      const updatedFaqData = faqData.filter((faq) => faq.id !== faqId);
      setFaqData(updatedFaqData);
    } catch (error) {
      console.error("Error deleting FAQ:", error);
    }
  };

  const handleToggleForm = () => {
    setQuestion("");
    setAnswer("");
    setEditMode(false);
    setFaqId("");
    setShowForm(!showForm);
  };

  return (
    <div>
      {!showForm && (
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleToggleForm}
        >
          Add New FAQ
        </button>
      )}

      {showForm && (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="question">Question:</label>
            <input
              type="text"
              className="form-control"
              id="question"
              placeholder="Enter the question"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="">Answer:</label>
            <MyEditor comment={answer} setComment={setAnswer} />
          </div>
          <button type="submit" className="btn btn-primary">
            {editMode ? "Update" : "Submit"}
          </button>
        </form>
      )}

      {faqData.map((faq) => (
        <div key={faq.id}>
          <h3>{faq.question}</h3>
          <div dangerouslySetInnerHTML={{ __html: faq.answer }}></div>
          <div>
            <button onClick={() => handleEdit(faq.id)}>Edit</button>
            <button onClick={() => handleDelete(faq.id)}>Delete</button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FQA;
