import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  doc,
  deleteDoc,
  arrayUnion,
  getFirestore,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { setChats } from "../ChatSlice";
import Constants from "../../constants/Constants";
import axios from "axios";
const WHATSAPP_END_POINT = Constants.whatsapp.WHATSAPP_END_POINT;
const headers = {
  Authorization: `Bearer ${Constants.whatsapp.WHATSAPP_TOKEN}`,
  "Content-Type": "application/json",
};

export const subscribeToChats = (dispatch, userDepartment) => {
  const chatsRef = collection(db, "chats");
  const q = query(
    chatsRef,
    where("Status", "!=", Constants.chatStages.ARCHIVED),
    where("ParentCategory", "==", userDepartment)
    // where("Status", "!=", Constants.chatStages.BLOCKED)
  );

  const unsubscribe = onSnapshot(q, (snapshot) => {
    const chats = snapshot.docs.map((doc) => {
      const chatData = doc.data();
      // chatData.DateCreated = chatData.DateCreated.toDate();
      // chatData.DateUpdated = chatData.DateUpdated.toDate();

      return { id: doc.id, ...chatData };
    });

    const updatedChats = chats.filter(
      (chat) => chat.Status != Constants.chatStages.BLOCKED
    );

    dispatch(setChats(updatedChats));
  });

  return unsubscribe;
};

export const updateChat = async (chatId, message) => {
  try {
    const db = getFirestore();
    const chatRef = doc(db, "chats", chatId);
    const payload = {
      MessageType: "text",
      MessageContent: message,
      Sender: "AGENT",
      DateCreated: new Date().toString(),
      IsRead: false,
    };

    updateDoc(chatRef, {
      Messages: arrayUnion(payload),
    });
  } catch (error) {
    console.error("Error updating array:", error);
  }
};

export const updateChatStatusApi = async (chatId, status, reason) => {
  try {
    const db = getFirestore();
    const chatRef = doc(db, "chats", chatId);

    let updateData = {
      Status: status,
      DateUpdate: new Date().toDateString(),
    };

    if (reason && reason.length > 0) {
      updateData.ReasonForBlocking = reason;
    }

    updateDoc(chatRef, updateData);
  } catch (error) {
    console.error("Error updating chat status:", error);
  }
};

// export const closeChatApi = async (chatId) => {
//   try {
//     const db = getFirestore();
//     const chatRef = doc(db, "chats", chatId);

//     updateDoc(chatRef, {
//       Status: Constants.chatStages.CLOSED,
//       DateUpdate: new Date().toDateString(),
//     });
//   } catch (error) {
//     console.error("Error updating chat status:", error);
//   }
// };

export const addChat = async (message) => {
  try {
    const chatRef = collection(db, "chats");
    const chatData = {
      message,
      DateCreated: new Date(),
      DateUpdated: new Date(),
    };

    await addDoc(chatRef, chatData);

    return { success: true }; // Return a plain object action indicating success
  } catch (error) {
    return { success: false, error }; // Return a plain object action indicating failure
  }
};

export const removeChat = async (dispatch, chatId) => {
  try {
    const chatsRef = doc(collection(db, "chats"), chatId);
    await deleteDoc(chatsRef);
    dispatch(setChats([])); // Update the store with the updated chat list
  } catch (error) {
    console.log("Error removing chat:", error);
  }
};

export const sendWhatsAppMessage = (whatsAppId, messageBody) => {
  const payload = {
    messaging_product: "whatsapp",
    recipient_type: "individual",
    to: whatsAppId,
    type: "text",
    text: {
      preview_url: false,
      body: messageBody,
    },
  };

  return new Promise((resolve, reject) => {
    axios
      .post(WHATSAPP_END_POINT, payload, {
        headers: headers,
      })
      .then((res) => {
        resolve("ok");
      })
      .catch((error) => {
        console.log("Error occured: ", error);
        reject(error);
      });
  });
};
