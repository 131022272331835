import { createSlice } from "@reduxjs/toolkit";
import { getRequesters } from "./api/requestersApi";

const initialState = {
  requesters: [],
};

const requesterSlice = createSlice({
  name: "requester",
  initialState,
  reducers: {
    setRequesters: (state, action) => {
      state.requesters = action.payload;
    },
  },
});

export const { setRequesters } = requesterSlice.actions;

export const fetchRequestersAsync = async (dispatch) => {
  const requesters = await getRequesters();
  dispatch(setRequesters(requesters));
};

export default requesterSlice;
