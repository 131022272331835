import { createSlice } from "@reduxjs/toolkit";

import ConfigsService from "../services/configsService";
const configsService = new ConfigsService();
const initialState = {
  caseStatuses: [],
  impacts: [],
  priorities: [],
};

const configSlice = createSlice({
  name: "configs",
  initialState,
  reducers: {
    setConfigs: (state, action) => {
      state.caseStatuses = action.payload.caseStatuses;
      state.impacts = action.payload.impacts;
      state.priorities = action.payload.priorities;
    },
  },
});

export const { setConfigs } = configSlice.actions;

export const fetchConfigsAsync = async (dispatch) => {
  const configs = await configsService.getConfigs();
  dispatch(setConfigs(configs));
};

export default configSlice;
