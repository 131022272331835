import axios from "axios";
const headers = {
  // Authorization: `Bearer ${Constants.whatsapp.WHATSAPP_TOKEN}`,
  "Content-Type": "application/json",
};
const REQUESTERS_ENDPOINT = "http://localhost:7001/requesters";
export const getRequesters = async () => {
  const result = await axios.get(REQUESTERS_ENDPOINT, { headers });
  if (result.data) {
    return result.data;
  }
  return [];
};
