import React from "react";
import ChatHistory from "../../components/chats/ChatHistory";
import MessageView from "../../components/chats/MessageView"

const Chats = () => {
    return(
        <>
            <div id="chats">
                <ChatHistory />
                <MessageView />
            </div>
        </>
    )
}

export default Chats;