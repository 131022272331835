import React, { useState, useEffect } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import { useDispatch, useSelector } from "react-redux";
import { subscribeToCases } from "../../slices/api/casesApi";
import { setActiveCase } from "../../slices/CasesSlice";
import { useNavigate } from "react-router-dom";

const CasesTable = () => {
  const dispatch = useDispatch();
  const cases = useSelector((state) => state.cases.casesList);
  const authUser = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(cases.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = cases.slice(startIndex, endIndex);

  const handlePrevPage = () => {
    setCurrentPage((currentPage) => currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((currentPage) => currentPage + 1);
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const sortedItems = () => {
    if (sortColumn) {
      return currentItems.sort((a, b) => {
        const valueA = a[sortColumn].toLowerCase();
        const valueB = b[sortColumn].toLowerCase();

        if (valueA < valueB) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortOrder === "asc" ? 1 : -1;
        }
        return 0;
      });
    }

    return currentItems;
  };

  useEffect(() => {
    const user = authUser.user;
    const unsubscribe = subscribeToCases(dispatch, user);

    // return () => {
    //   unsubscribe();
    // };
  }, [dispatch]);

  const handleSetActiveCase = (item) => {
    dispatch(setActiveCase(item));
    navigate("single_case/" + item.id);
  };

  return (
    <>
    {currentItems.length > 0 ? (
      <table className="cases-table">
      <thead>
        <tr style={{ backgroundColor: "#f2f2f2", padding: "0.5em" }}>
          <th onClick={() => handleSort("caseNumber")}>
            Case #
            {sortColumn === "caseNumber" &&
              (sortOrder === "asc" ? <>&uarr;</> : <>&darr;</>)}
          </th>
          <th onClick={() => handleSort("caseSummary")}>
            Case summary
            {sortColumn === "caseSummary" &&
              (sortOrder === "asc" ? <>&uarr;</> : <>&darr;</>)}
          </th>
          <th onClick={() => handleSort("category")}>
            Category
            {sortColumn === "category" &&
              (sortOrder === "asc" ? <>&uarr;</> : <>&darr;</>)}
          </th>
          <th onClick={() => handleSort("assignedTo")}>
            Assigned to
            {sortColumn === "assignedTo" &&
              (sortOrder === "asc" ? <>&uarr;</> : <>&darr;</>)}
          </th>
          <th onClick={() => handleSort("status")}>
            Status
            {sortColumn === "status" &&
              (sortOrder === "asc" ? <>&uarr;</> : <>&darr;</>)}
          </th>
        </tr>
      </thead>
      <tbody>
        {sortedItems().map((item, index) => (
          <tr
            key={index}
            style={{ backgroundColor: "white", padding: "0.5em" }}
            onClick={() => handleSetActiveCase(item)}
          >
            <td>{item.caseNumber}</td>
            <td>{item.caseSummary}</td>
            <td>{item.category}</td>
            <td>{item.assignedTo}</td>
            <td>{item.status}</td>
          </tr>
        ))}
      </tbody>
    </table>
    ) : (<p>You never logged any cases!!!</p>)}
      
      {currentItems.length > 0 && ( 
        <div className="pagination-container">
        <button onClick={handleFirstPage} disabled={currentPage === 1}>
          <SkipPreviousIcon />
        </button>
        <button onClick={handlePrevPage} disabled={currentPage === 1}>
          <NavigateBeforeIcon />
        </button>
        <span>
          {currentPage} / {totalPages}
        </span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          <NavigateNextIcon />
        </button>
        <button onClick={handleLastPage} disabled={currentPage === totalPages}>
          <SkipNextIcon />
        </button>
      </div>)}
    </>
  );
};

export default CasesTable;
