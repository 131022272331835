import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  doc,
  deleteDoc,
  arrayUnion,
  getFirestore,
  updateDoc,
  setDoc,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";

export const addFAQToFirestore = async (question, answer) => {
  try {
    const docRef = await addDoc(collection(db, "faq"), {
      question: question,
      answer: answer,
    });
    console.log("Document written with ID: ", docRef.id);
    return docRef.id;
  } catch (error) {
    console.error("Error adding document: ", error);
    throw error;
  }
};

export const fetchFAQData = async () => {
  try {
    const faqCollectionRef = collection(db, "faq");
    const faqSnapshot = await getDocs(faqCollectionRef);

    return faqSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error("Error fetching FAQ data:", error);
    return [];
  }
};

// Update FAQ in Firestore
export const updateFAQInFirestore = async (faqId, question, answer) => {
  try {
    const faqRef = doc(db, "faq", faqId);
    await updateDoc(faqRef, { question, answer });
    console.log("FAQ updated with ID: ", faqId);
  } catch (error) {
    // If an error occurs during update, delete the newly created document
    await deleteDoc(doc(db, "faq", faqId));
    console.error("Failed to update FAQ: ", error);
    throw new Error("Failed to update FAQ: " + error.message);
  }
};

// Delete FAQ from Firestore
export const deleteFAQFromFirestore = async (faqId) => {
  try {
    await deleteDoc(doc(db, "faq", faqId));
    console.log("FAQ deleted with ID: ", faqId);
  } catch (error) {
    throw new Error("Failed to delete FAQ: " + error.message);
  }
};
