import { createSlice } from "@reduxjs/toolkit";
import {
  subscribeToChats,
  addChat as addChatAPI,
  removeChat as removeChatAPI,
} from "./api/chatsAPI";
import Constants from "../constants/Constants";

const initialState = {
  conversations: [],
  activeChat: null,
  idForChatCurrentlyAttending: null,
  chatStatusFilter: Constants.chatStages.CHAT_OPENED,
};

const chatsSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setChats: (state, action) => {
      state.conversations = action.payload;
    },
    addChat: (state, action) => {
      state.conversations.push(action.payload);
    },
    setActiveChat: (state, action) => {
      state.activeChat = action.payload;
    },
    setChatStatusFilter: (state, action) => {
      state.chatStatusFilter = action.payload;
    },
    setIdForChatCurrentlyAttending: (state, action) => {
      state.idForChatCurrentlyAttending = action.payload;
    },
  },
});

export const {
  setChats,
  addChat,
  setActiveChat,
  setChatStatusFilter,
  setIdForChatCurrentlyAttending,
} = chatsSlice.actions;

export const fetchChatsAsync = () => (dispatch) => {
  const unsubscribe = subscribeToChats(dispatch);

  // Return a cleanup function to unsubscribe when needed
  return () => {
    unsubscribe();
  };
};

export const addChatAsync = (chat) => async (dispatch) => {
  try {
    const newChat = await addChatAPI(chat);
    dispatch(addChat(newChat));
  } catch (error) {
    console.log("Error adding chat:", error);
  }
};

export default chatsSlice;
