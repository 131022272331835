import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useSelector } from "react-redux";
import moment from "moment";
import SingleCaseTabs from "./SingleCaseViewTabs";
import ConversationTab from "./single-case-tabs/ConversationTab";
import DetailsTab from "./single-case-tabs/DetailsTab";
import ResolutionTab from "./single-case-tabs/ResolutionTab";
import ReplyIcon from "@mui/icons-material/Reply";
import AttachmentsTab from "./single-case-tabs/AttachmentsTab";

const SingleCaseView = () => {
  const navigate = useNavigate();
  const activeCase = useSelector((state) => state.cases.activeCase);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("activeTab") || "conversation";

  const handleTabChange = (tab) => {
    navigate(`?activeTab=${tab}`);
  };

  return (
    <div id="single_case_view">
      <div className="header-part">
        <div className="action-btns">
          <button
            type="button"
            className="back-btn"
            onClick={() => navigate("/cases")}
          >
            <ChevronLeftIcon /> Back
          </button>
          {/* <div className="more-btns">
            <button type="button">Edit</button>
            <button type="button">Close</button>
            <button type="button">Cancel</button>
            <button type="button">Pick Up</button>
            <button type="button">Assign</button>
            <button type="button">Print</button>
          </div> */}
        </div>
      </div>
      <div className="single-case">
        <div className="case-header">
          <h4>{activeCase.caseSummary}</h4>
          <div className="header-footer">
            <p>
              Priority: {activeCase.priority}
              <span className="v-separator"></span> Requested by&nbsp;
              <span className="global-name">{activeCase.caseRequester}</span>
              &nbsp;on{" "}
              {moment
                .utc(new Date(activeCase.dateCreated), "MM/DD/YYYY h:mm A")
                .format("MMM DD, YYYY HH:mm")}
            </p>
            <button type="button" className="reply-button">
              <ReplyIcon fontSize="small" /> Comment
            </button>
          </div>
        </div>
        <div className="tabs-container">
          <SingleCaseTabs activeTab={activeTab} onTabChange={handleTabChange} />
        </div>
        <div className="content-container">
          {activeTab === "conversation" && <ConversationTab />}
          {activeTab === "details" && <DetailsTab />}
          {activeTab === "resolution" && <ResolutionTab />}
          {activeTab === "attachments" && <AttachmentsTab />}
        </div>
        {/* {JSON.stringify(activeCase)} */}
      </div>
    </div>
  );
};

export default SingleCaseView;
